import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import { mobile, tablet, PageHeader, Headline, Subtitle } from '~styles/global'
import Section from '~components/Section'
import styled from '@emotion/styled'

const ContactPage = ({ data }) => {
	const { page } = data
	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<StyledPageHeader>
				<Headline>{page.headline}</Headline>
				<Subtitle>{page.subtitle}</Subtitle>
			</StyledPageHeader>
			<Section>
				<ContactGrid>
					<Line>
						<h6>
							Phone
						</h6>
						<ATag href={`tel:${page.phone}`}>
							{page.phone}
						</ATag>
					</Line>
					<Line>
						<h6>
							Email
						</h6>
						<ATag href={`mailto:${page.email}`}>
							{page.email}
						</ATag>
					</Line>
				</ContactGrid>
			</Section>
			<Contacts>
				<Headline>{page.contactsTitle}</Headline>
				<ContactsSubtitle>{page.contactsSubtitle}</ContactsSubtitle>
				{page.contacts.map(c => (
					<Contact key={c.title}>
						<div>{c.title}</div>
						<ATag>{c.phone}</ATag>
					</Contact>
				))}

			</Contacts>
			<DataViewer data={page} name="page"/>
		</>
	)
}

ContactPage.propTypes = {
	data: PropTypes.object,
}

const StyledPageHeader = styled(PageHeader)`
	margin-bottom: var(--l);
`
const ContactGrid = styled.div`
	border-top: 1px solid;
	grid-column: 3/11;
	width: 100%;
	max-width: 440px;
	margin: 0 auto var(--xxxxl);
	${mobile}{
		grid-column: span 12;
	}
`
const Line = styled.div`
	display: flex;
	padding: var(--s) 0 var(--m);
	border-bottom: 1px solid;
	justify-content: space-between;
`
const Contacts = styled(Section)`
	margin-bottom: var(--xxxxl);
`
const ContactsSubtitle = styled(Subtitle)`
	margin-bottom: var(--l);
`
const Contact = styled.div`
	margin-bottom: var(--l);
	grid-column: 3/11;
	width: 100%;
	max-width: 440px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	:last-of-type{
		margin-bottom: 0;
	}
	${mobile}{
		grid-column: span 12;
	}
`
const ATag = styled.a`
	display: block;
`

export const query = graphql`
  query ContactPageQuery($slug: String) {
    page: sanityContactPage(slug: {current: {eq: $slug}}) {
      title
			headline
			subtitle
			phone
			email
			contactsTitle
			contactsSubtitle
			contacts {
				title
				phone
			}
			seo {
				...seo
			}
    }
  }
`

export default ContactPage